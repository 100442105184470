'use client';

import { useEffect, useState } from 'react';

import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { useMutationHandler } from '@module/mdrt-org/shared/utils/hooks';
import { type SectionsData, AccountSettingsPreviewModal } from '@shared/ui-components';

import { type AccountSettingsPreviewModalWrapperContent } from './get-account-settings-preview-modal-wrapper-content';

type AccountSettingsPreviewModalWrapperProps = {
  cmsContent: AccountSettingsPreviewModalWrapperContent;
  initialIsModalOpen: boolean;
  personId?: number;
  sectionsData: SectionsData;
};

export const AccountSettingsPreviewModalWrapper = ({
  cmsContent,
  initialIsModalOpen,
  personId,
  sectionsData,
}: AccountSettingsPreviewModalWrapperProps) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const { mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<{ promptUserToReviewData: boolean }>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    scrollToTop: false,
    showSuccessToast: false,
  });

  const handleModalDismiss = () => {
    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.UserDataReviewConfirmation,
        promptUserToReviewData: false,
      },
      personId,
    });
  };

  useEffect(() => {
    const settingsPreviewModalWasDisplayed =
      sessionStorage.getItem('settingsPreviewModalWasDisplayed') === 'true';

    if (initialIsModalOpen && !settingsPreviewModalWasDisplayed) {
      setIsPreviewModalOpen(true);
      sessionStorage.setItem('settingsPreviewModalWasDisplayed', 'true');
    }
  }, [initialIsModalOpen]);

  return (
    <AccountSettingsPreviewModal
      cmsContent={cmsContent}
      dismissModalHandler={handleModalDismiss}
      initialIsModalOpen={isPreviewModalOpen}
      sectionsData={sectionsData}
    />
  );
};
