import * as React from 'react';
import { type SVGProps } from 'react';

export const HeroBackgroundGraphics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-labelledby="hero-backgroud-graphics-svg"
    fill="none"
    height="431"
    viewBox="0 0 396 431"
    width="396"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M15 2H13V13H2V15H13V26H15V15H26V13H15V2Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 2H61V13H50V15H61V26H63V15H74V13H63V2Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111 2H109V13H98V15H109V26H111V15H122V13H111V2Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M159 2H157V13H146V15H157V26H159V15H170V13H159V2Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15 50H13V61H2V63H13V74H15V63H26V61H15V50Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15 98H13V109H2V111H13V122H15V111H26V109H15V98Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15 146H13V157H2V159H13V170H15V159H26V157H15V146Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 50H61V61H50V63H61V74H63V63H74V61H63V50Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 90H61V109H42V111H61V130H63V111H82V109H63V90Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 146H61V157H50V159H61V170H63V159H74V157H63V146Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111 50H109V61H98V63H109V74H111V63H122V61H111V50Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111 98H109V109H98V111H109V122H111V111H122V109H111V98Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M111 146H109V157H98V159H109V170H111V159H122V157H111V146Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M159 50H157V61H146V63H157V74H159V63H170V61H159V50Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M159 98H157V109H146V111H157V122H159V111H170V109H159V98Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M159 146H157V157H146V159H157V170H159V159H170V157H159V146Z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <g clipPath="url(#clip0_0_1)">
      <rect fill="white" height="56" width="56" x="60" y="319" />
      <rect fill="#FFD626" height="56" width="56" x="116" y="263" />
      <rect fill="white" height="56" width="56" x="172" y="207" />
      <path
        clipRule="evenodd"
        d="M228 263H284V319H340V375H284V431H228V375H172V319H228V263Z"
        fill="#8EE4F6"
        fillRule="evenodd"
      />
      <path d="M396 207H228V39.5L396 207Z" fill="#FFD626" />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect fill="white" height="391" transform="translate(60 40)" width="336" />
      </clipPath>
    </defs>
  </svg>
);
