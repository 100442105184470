const BIOMETRIC_AUTHENTICATION_DISMISSED_KEY = 'biometric-auth-dismissed';

export const onDismissBiometricAuthenticationModal = () => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(BIOMETRIC_AUTHENTICATION_DISMISSED_KEY, 'true');
  }
};

export const isBiometricAuthenticationDismissed = () => {
  if (typeof window !== 'undefined') {
    const wasDismissed = localStorage?.getItem(BIOMETRIC_AUTHENTICATION_DISMISSED_KEY);
    return wasDismissed !== null && wasDismissed === 'true';
  }

  return false;
};
