import { formatTranslationStringOnClient } from '@shared/utils';

import { getMembershipInformation } from '../../helpers';
import {
  type ApplicationDefinition,
  type CustomerProfile,
  type MembershipApplicationDto,
} from '../../types';

import { getInformationChipData, type InformationChipCMSContent } from './utils/helpers';

export const getInformationChipProps = ({
  applicationDefinition,
  cmsContent,
  customerProfile,
  handleUpgradeMembershipClick,
  membershipApplication,
  membershipApplicationUrl,
}: {
  applicationDefinition?: ApplicationDefinition;
  cmsContent: InformationChipCMSContent;
  customerProfile: CustomerProfile;
  handleUpgradeMembershipClick: () => void;
  membershipApplication: MembershipApplicationDto | null;
  membershipApplicationUrl: string;
}) => {
  const {
    isApplicationInReview,
    isApplicationUnfinished,
    isCurrentMember,
    isLessThanTenDaysLeftUntilLateFees,
    isLessThanThirtyDaysLeftUntilLateFees,
    isMemberAbleToUpgrade,
    isNewUser,
    isPreviousButNotCurrentMember,
    isThirtyToTenDaysLeftUntilLateFees,
    isUpgrading,
    subtractCurrentDateFromLateFeeStartDate,
  } = getMembershipInformation({
    applicationDefinition,
    customerProfile,
    membershipApplication,
  });

  return getInformationChipData({
    cmsContent: {
      ...cmsContent,
      numberOfDaysLeft: formatTranslationStringOnClient(cmsContent.numberOfDaysLeft, {
        daysLeft:
          subtractCurrentDateFromLateFeeStartDate &&
          Math.round(subtractCurrentDateFromLateFeeStartDate),
      }),
    },
    handleUpgradeMembershipClick,
    isApplicationInReview,
    isApplicationUnfinished,
    isCurrentMember,
    isLessThanTenDaysLeftUntilLateFees,
    isLessThanThirtyDaysLeftUntilLateFees,
    isMemberAbleToUpgrade,
    isNewUser,
    isPreviousButNotCurrentMember,
    isThirtyToTenDaysLeftUntilLateFees,
    isUpgrading,
    membershipApplicationUrl,
  });
};
