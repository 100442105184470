'use client';

import { Chip } from '@module/mdrt-org/shared/components';
import { ChipTheme } from '@shared/ui-components';

import { type CompanyAdminSectionContent } from '../hero-section-wrapper-server';

import styles from './hero-section-company-admin.module.scss';

export const HeroSectionCompanyAdmin = ({
  cmsContent,
  showCompanyAdminSection,
}: {
  cmsContent: CompanyAdminSectionContent;
  showCompanyAdminSection: boolean;
}) => {
  if (!showCompanyAdminSection) return null;

  return (
    <div className={styles['hero-section-company-admin']}>
      <p className={styles['hero-section-company-admin__description']}>
        {cmsContent.companyAdminSectionDescriptionLabel}
      </p>
      <Chip
        className={styles['hero-section-company-admin__action']}
        isSmall
        label={cmsContent.companyAdminButtonLabel}
        theme={ChipTheme.Emphasis}
        to={cmsContent.companyAdminDashboardPagePath}
      />
    </div>
  );
};
