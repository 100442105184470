import classNames from 'classnames';

import { AddPhotoIcon } from '@shared/ui-components';

import styles from './upload-photo-placeholder.module.scss';

export type UploadPhotoPlaceholderContentType = {
  pictureDescription: string;
};

type UploadPhotoPlaceholderProps = {
  actionLabelText?: string;
  cmsContent: UploadPhotoPlaceholderContentType;
  hasHoverOverlay?: boolean;
  imageSrc: string | undefined;
};

export const UploadPhotoPlaceholder = ({
  actionLabelText,
  cmsContent,
  hasHoverOverlay,
  imageSrc,
}: UploadPhotoPlaceholderProps) => {
  const imageAltText = hasHoverOverlay
    ? `${cmsContent.pictureDescription}. ${actionLabelText}`
    : cmsContent.pictureDescription;

  const renderOverlay = (
    <div
      aria-hidden={hasHoverOverlay}
      className={classNames(styles['photo-placeholder__content'], {
        [styles['photo-placeholder__content--hover']]: hasHoverOverlay,
      })}
      data-testid="photo-placeholder-overlay"
    >
      <AddPhotoIcon className={styles['photo-placeholder__icon']} />
      <span>{actionLabelText}</span>
    </div>
  );

  return (
    <div className={styles['photo-placeholder']}>
      {imageSrc ? (
        <>
          <div
            className={styles['photo-placeholder__content']}
            data-testid="photo-placeholder-content"
          >
            <img
              alt={imageAltText}
              className={styles['photo-placeholder__image']}
              data-testid="photo-placeholder-image"
              src={imageSrc}
            />
          </div>
          {hasHoverOverlay && renderOverlay}
        </>
      ) : (
        renderOverlay
      )}
    </div>
  );
};
