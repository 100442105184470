import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { type Dispatch, type SetStateAction, useTransition } from 'react';
import { useForm } from 'react-hook-form';

import { ButtonVariant, Dialog, FormWrapper } from '@shared/ui-components';
import {
  ButtonType,
  formBaseConfig,
  PICTURE_UPLOAD_SERVER_ERROR_KEY,
  type ProfilePictureData,
  type ServerActionResponse,
  useProfilePictureUpload,
} from '@shared/utils';

import { UploadPhotoDialogActions } from '../upload-photo-dialog-actions/upload-photo-dialog-actions';

import {
  type PhotoUploadResponse,
  type ProfilePhotoContainerContent,
} from './profile-photo-container';
import { ProfilePhotoDialogContent } from './profile-photo-dialog-content';
import styles from './profile-photo-dialog.module.scss';

const usePictureUploadMutation = ({
  handleSubmitProfilePicture,
  onPhotoUploadSuccess,
}: {
  handleSubmitProfilePicture: (formData: BodyInit) => Promise<PhotoUploadResponse>;
  onPhotoUploadSuccess: (response: PhotoUploadResponse) => void;
}) => {
  return useMutation({
    mutationFn: handleSubmitProfilePicture,
    onSuccess: onPhotoUploadSuccess,
  });
};

export const ProfilePhotoDialog = ({
  cmsContent,
  handleModalClose,
  handleProfilePictureDelete,
  handleSubmitProfilePicture,
  initialProfilePicture,
  setProfilePictureInSection,
}: {
  cmsContent: ProfilePhotoContainerContent;
  handleModalClose: () => void;
  handleProfilePictureDelete: () => Promise<ServerActionResponse>;
  handleSubmitProfilePicture: (formData: BodyInit) => Promise<PhotoUploadResponse>;
  initialProfilePicture: string | undefined;
  setProfilePictureInSection: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [isDeletePending, startDeleteTransition] = useTransition();
  const formMethods = useForm<ProfilePictureData>({
    ...formBaseConfig,
    defaultValues: {
      file: null,
    },
  });

  const fileInputError = formMethods.formState.errors.file?.message;

  const { previewPictureSource, rules, updatePreviewImageSource } = useProfilePictureUpload({
    fileSizeErrorMessage: cmsContent.fileSizeError,
    fileTypeErrorMessage: cmsContent.fileTypeError,
    initialProfilePicture,
  });

  const { isPending: isUploadPhotoMutationPending, mutate: uploadPhoto } = usePictureUploadMutation(
    {
      handleSubmitProfilePicture,
      onPhotoUploadSuccess: (response) => {
        if (response === null) {
          // todo refactor mutation handler
          // request was aborted by the user
          return;
        }

        if (response && ('messageTranslationKey' in response || 'message' in response)) {
          formMethods.setError('file', {
            message:
              response.messageTranslationKey === PICTURE_UPLOAD_SERVER_ERROR_KEY
                ? cmsContent.fileServerError
                : response.message,
            type: PICTURE_UPLOAD_SERVER_ERROR_KEY,
          });

          return;
        }

        setProfilePictureInSection(previewPictureSource);
        formMethods.resetField('file');

        handleModalClose();
      },
    }
  );

  const onSubmit = async (data: ProfilePictureData) => {
    const formData = new FormData();

    if (data.file?.[0]) {
      formData.append('file', data.file[0]);
      uploadPhoto(formData);
    }
  };

  const primaryButtonProps = {
    primaryButtonDisabled: !formMethods.formState.isDirty || !formMethods.getValues('file')?.length,
    primaryButtonLabel: previewPictureSource ? cmsContent.save : undefined,
    primaryButtonType: previewPictureSource ? ButtonType.SUBMIT : undefined,
  };

  const tertiaryButtonProps = (initialProfilePicture || previewPictureSource) && {
    onTertiaryButtonClick: () =>
      startDeleteTransition(async () => {
        const result = await handleProfilePictureDelete();

        if (result.status === 200) {
          formMethods.setValue('file', null);
          setProfilePictureInSection(undefined);
          handleModalClose();
        }
      }),
    tertiaryButtonLabel: cmsContent.remove,
  };

  const fileInputProps = {
    dataTestId: 'file-input',
    fileInputError: fileInputError !== undefined,
    fileInputLabel:
      initialProfilePicture || previewPictureSource ? cmsContent.change : cmsContent.add,
    fileInputName: 'file',
    fileInputRules: rules,
    fileInputVariant:
      initialProfilePicture || previewPictureSource ? ButtonVariant.Secondary : undefined,
    name: 'file',
    onFileInputChange: updatePreviewImageSource,
  };

  return (
    <Dialog
      closeButtonLabel={cmsContent.close}
      isLoading={isUploadPhotoMutationPending || isDeletePending}
      onModalClose={() => {
        formMethods.resetField('file');
        handleModalClose();
      }}
      title={cmsContent.modalTitle}
    >
      <FormWrapper<ProfilePictureData>
        className={classNames(styles['form'], {
          [styles['form--wide']]: tertiaryButtonProps,
        })}
        dataTestId="profile-photo-container-form"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <ProfilePhotoDialogContent
          cmsContent={{
            modalDescription: cmsContent.modalDescription,
            pictureDescription: cmsContent.pictureDescription,
            pictureFileInputHint: cmsContent.pictureFileInputHint,
            upload: cmsContent.upload,
          }}
          imageSrc={previewPictureSource}
          profileUploadError={fileInputError}
        />
        <UploadPhotoDialogActions
          {...primaryButtonProps}
          {...fileInputProps}
          {...tertiaryButtonProps}
        />
      </FormWrapper>
    </Dialog>
  );
};
