'use client';

import { useEffect, useState } from 'react';

import { useUserContext } from '@module/mdrt-org/shared/components';
import { loginHandler } from '@module/mdrt-org/shared/utils/helpers';
import { isMobileUserAgent } from '@module/mdrt-org/shared/utils/helpers/is-mobile-device';
import { AuthProviderName, parseJwtToken } from '@shared/misc';
import {
  BiometricIdIcon,
  Button,
  ButtonVariant,
  Dialog,
  ModalTrigger,
} from '@shared/ui-components';
import { useMounted } from '@shared/utils';

import { type BiometricAuthenticationModalContent } from './get-biometric-id-content';
import {
  isBiometricAuthenticationDismissed,
  onDismissBiometricAuthenticationModal,
} from './utils/biometric-auth-utils';
import styles from './biometric-id-modal.module.scss';

type BiometricIdModalProps = {
  cmsContent: BiometricAuthenticationModalContent;
  locale: string;
};

export const BiometricIdModal = ({ cmsContent, locale }: BiometricIdModalProps) => {
  const { accessToken, userId } = useUserContext();

  const parsedToken = accessToken ? parseJwtToken(accessToken) : undefined;

  const isModalComponentMounted = useMounted();

  const shouldModalBeOpened =
    typeof navigator !== 'undefined' &&
    isMobileUserAgent(navigator.userAgent) &&
    isModalComponentMounted &&
    !isBiometricAuthenticationDismissed() &&
    parsedToken !== undefined &&
    (parsedToken?.idp === undefined || parsedToken?.idp !== 'fido'); // Check if Biometric Authentication was used

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(shouldModalBeOpened);
  }, [shouldModalBeOpened]);

  return (
    <ModalTrigger isOpen={isModalOpen}>
      {() => (
        <Dialog>
          <div className={styles['modal']}>
            <div className={styles['modal__icon']}>
              <BiometricIdIcon />
            </div>
            <div className={styles['modal__content-wrapper']}>
              <p className={styles['modal__header']}>{cmsContent.heading}</p>
              <p className={styles['modal__content']}>{cmsContent.loginMessage}</p>
              <p className={styles['modal__content']}>{cmsContent.disableMessage}</p>
            </div>
            <div className={styles.divider} />
            <div className={styles['modal__footer']}>
              <Button
                onClick={() => {
                  onDismissBiometricAuthenticationModal();
                  setIsModalOpen(false);
                }}
                variant={ButtonVariant.Secondary}
              >
                {cmsContent.dismiss}
              </Button>
              <Button
                onClick={() =>
                  loginHandler({
                    authorizationParameters: {
                      ui_locales: locale,
                      userId,
                    },
                    provider: AuthProviderName.BiometricRegistration,
                  })
                }
              >
                {cmsContent.enable}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </ModalTrigger>
  );
};
