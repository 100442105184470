'use client';

import {
  CarouselCard as CarouselCardBase,
  type CarouselCardProps as CarouselCardBaseProps,
} from '@shared/ui-components';

import { withCustomLinkComponent } from '../../i18n/with-custom-link-component';

export type CarouselCardProps = Omit<CarouselCardBaseProps, 'LinkComponent'>;

export const CarouselCard = (props: CarouselCardProps) =>
  withCustomLinkComponent(CarouselCardBase, props);
