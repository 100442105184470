import { type PropsWithChildren } from 'react';

import { ContentContainer } from '@module/mdrt-org/shared/components';

import { HeroBackgroundGraphics } from '../svgs/hero-background-graphics';

import styles from './hero-section-content-wrapper.module.scss';

export const HeroSectionContentWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles['hero-section-content-wrapper']} data-testid="hero-section">
      <ContentContainer>
        <div className={styles['content']}>
          {children}
          <div className={styles['hero-section-content-wrapper__background']} />
        </div>
        <HeroBackgroundGraphics className={styles['background-graphics']} />
      </ContentContainer>
    </div>
  );
};
