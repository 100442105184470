import { type PropsWithChildren } from 'react';

import styles from './profile-summary-actions.module.scss';

export const ProfileSummaryActions = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={styles['profile-summary-actions']}
      data-testid="profile-summary-action-container"
    >
      {children}
    </div>
  );
};
