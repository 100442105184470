import { type ChangeEvent } from 'react';
import { type RegisterOptions } from 'react-hook-form';

import { ButtonVariant, Button, FileInput } from '@shared/ui-components';
import { type ButtonType } from '@shared/utils';

import styles from './upload-photo-dialog-actions.module.scss';

export type FileInputProps = {
  fileInputError: boolean;
  fileInputLabel?: string;
  fileInputName: string;
  fileInputRules: RegisterOptions;
  fileInputVariant?: ButtonVariant;
  onFileInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type UploadPhotoDialogActionsProps = {
  onPrimaryButtonClick?: () => void;
  onTertiaryButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonLabel?: string;
  primaryButtonType?: ButtonType;
  tertiaryButtonLabel?: string;
} & FileInputProps;

export const UploadPhotoDialogActions = ({
  fileInputError,
  fileInputLabel,
  fileInputName,
  fileInputRules,
  fileInputVariant,
  onFileInputChange,
  onPrimaryButtonClick,
  onTertiaryButtonClick,
  primaryButtonDisabled,
  primaryButtonLabel,
  primaryButtonType,
  tertiaryButtonLabel,
}: UploadPhotoDialogActionsProps) => {
  return (
    <div className={styles['upload-photo-dialog-actions']}>
      <div className={styles['upload-photo-dialog-actions__delete-button-container']}>
        {onTertiaryButtonClick && tertiaryButtonLabel && (
          <Button
            dataTestId="dialog-delete-button"
            onClick={onTertiaryButtonClick}
            variant={ButtonVariant.Tertiary}
          >
            {tertiaryButtonLabel}
          </Button>
        )}
      </div>
      <div
        className={styles['upload-photo-dialog-actions__button-group-container']}
        data-testid="dialog-button-group"
      >
        {fileInputLabel && (
          <FileInput
            ariaDescribedBy="profile-picture-requirements"
            dataTestId={fileInputName}
            hasError={fileInputError}
            label={fileInputLabel}
            name={fileInputName}
            onChange={onFileInputChange}
            rules={fileInputRules}
            variant={fileInputVariant}
          />
        )}
        {primaryButtonLabel && (
          <Button
            dataTestId="dialog-submit-button"
            disabled={primaryButtonDisabled}
            onClick={onPrimaryButtonClick && onPrimaryButtonClick}
            type={primaryButtonType}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
